import React from 'react';
import car from '../car.png';
import truck from '../truck.png';

/* eslint-disable */

function Prices() {
  return (
    <div id='prices' className='prices'>
      <h1 className='py-5'>наши цены</h1>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-xm-12'>
            <div className='box'>
              <div className='circle'>
                <img className='prices-logo' src={car} alt='car...' />
              </div>
              <h3>Легковой шиномонтаж</h3>
              <p>Ремонт колеса пластырем - от 150 рублей</p>
              <p>Ремонт колеса грибком - от 200 рублей</p>
              <p>Ремонт бокового пореза/грыжи - от 1000 рублей</p>
              <p>Дошиповка зимней резины - 20 рублей</p>
              <p>Балансировка колес - 100 - 200 рублей</p>
              <p>Сезонная смена шин - 700 - 1450 рублей</p>
              <p>Ремонт шины - 100 - 3000 рублей</p>
              <p>Съем, установка колеса - 50 - 200 рублей</p>
              <p>Правка диска - 250 - 1000 рублей</p>
              <p>
                Проверка давления (подкачка) одного колеса - 10 рублей
              </p>
            </div>
          </div>
          {/* - */}
          <div className='col-lg-6 col-xm-12'>
            <div className='box'>
              <div className='circle'>
                <img
                  className='prices-logo'
                  src={truck}
                  alt='truck...'
                />
              </div>
              <h3>Грузовой шиномонтаж</h3>
              <p>Ремонт колеса пластырем - от 300 рублей</p>
              <p>Демонтаж пластыря - 1000 рублей</p>
              <p>Ремонт колеса грибком - от 450 рублей</p>
              <p>Ремонт бокового пореза/грыжи - от 2000 рублей</p>
              <p>Установка вентиля б/к - от 350 рублей</p>
              <p>
                Установка вентиля на камеру - от 400 - 500 рублей
                (тракторный 600)
              </p>
              <p>Ремонт камеры - от 100 - 200 рублей (1 заплатка)</p>
              <p>
                Проверка давления (подкачка) одного колеса - 25 рублей
              </p>
              <p>Клеевой 1 грамм - 0,5 рублей</p>
              <p>Продувка радиатора - 200 рублей</p>
              <p>Продувка фильтра - 100 рублей</p>
              <p>
                Протяжка гаек колеса - 100 рублей, Газель - 50 рублей
              </p>
              <p>Чистка диска - 100 рублей</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prices;
